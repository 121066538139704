<template>
	<div v-if="RelationPersonList.length > 0 && $UserID">
		<div class="listtitle_box m_t20 related_title">
			<i></i>
			<img src="../../images/xgrw_ico.png">
		</div>
		<div class="xgts_box detail_xgtsbox">
			<ul class="resource_imageRelated1">
				<li v-for="(value,index) in RelationPersonList" :key="index" v-show="index<2" @click="toElementDetail(value)">
					<div class="image_cover">
						<img  :src="$ImgUrl + value.CoverPath" :onerror="defaultImg" />
					</div>
					<div class="image_content">
						<h3>{{value.Name}}</h3>
						<p>{{value.Annotation | GetSubstring(28)}}</p>
					</div>
				</li>
			</ul>
			<ul class="resource_imageRelated2">
				<li v-for="(value,index) in RelationPersonList.slice(2)" :key="index">
					<div class="xgts_boxt">
						<a href="javascript:void(0)" @click="toElementDetail(value)">
							<img :src="$ImgUrl + value.CoverPath " :onerror="defaultImg" />
						</a>
					</div>
					<div class="xgts_boxb">
						<a href="javascript:void(0)" @click="toElementDetail(value)">
							<h3>{{value.Name}}</h3>
						</a>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import CheckInService from '../../api/CheckInService.js'
	export default{
		data(){
			return{
				defaultImg: 'this.src="' + require('../../images/picture1.jpg') + '"', //默认图片
				RelationPersonList:[],
			}
		},
		props: ['relatedType','topNum','resID'],
		methods:{
			GetRelationEntryPerson(){
				var that = this
				CheckInService.GetRelationEntryPerson(that.relatedType,that.resID,that.topNum).then(res=>{
					if(res.Tag == 1){
						that.RelationPersonList = res.Result?res.Result:[]
					}
				})
			},
			toElementDetail(value){ //词条详情
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.ID)
			}
		},
		mounted(){
			this.GetRelationEntryPerson()
		}
	}
</script>

<style>
	.image_content p{ float: left; font-size: 13px; color: #2e2e2e; line-height: 22px; margin-top: 16px;}
</style>
