/* eslint-disable */
import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import RelatedBook from '../../Related/RelatedBook.vue'
import RelatedElement from '../../Related/RelatedElement.vue'
import RelatedEntry from '../../Related/RelatedEntry.vue'
import RelatedImage from '../../Related/RelatedImage.vue'
import RelatedAudio from '../../Related/RelatedAudio.vue'
import RelatedVideo from '../../Related/RelatedVideo.vue'
import RelatedExport from '../../Related/RelatedExport.vue'
import LoginPop from '../../Login/LoginPop.vue'
import CheckInService from '../../../api/CheckInService.js'
import VIPLayer from '../../../components/VIPLayer.vue'

export default{
	name:'EntryDetail',
	data(){
		return{
			EntryModelInfo:'',//详细信息
			showLogin:false,
			collectionStatus:0,
			RecommendBookInfo:'',//推荐图书
		}
	},
	methods:{
		GetEntryModel(){ //获取条目详情
			var that = this
			CheckInService.GetEntryModel(that.$route.query.id,that.$UserID).then(res=>{
				console.log("res:",res);
				if(res.Tag == 1){
					if(res.Result.EntryModel.ReleaseStatus == 1){
						console.log("EntryModelInfo", res);
						that.EntryModelInfo = res.Result
						that.collectionStatus = res.Result.ComStatus
					}else{
						that.$alert('该资源未发布', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									window.close()
								}
								window.close()
							}
						});
					}
				}
			})
		},
		InsertCollection(){ //添加收藏
			var that = this
			if(that.$UserID){
				CheckInService.InsertUserColletion(that.$route.query.id,that.EntryModelInfo.EntryModel.entry_name,that.$UserID,1).then(res=>{
					if(res.Tag == 1){
						that.collectionStatus = 1
						that.$alert('收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		CancelCollection(){ //取消收藏
			var that = this
			if(that.$UserID){
				CheckInService.UpdateUserColletion(that.$UserID,that.$route.query.id).then(res=>{
					if(res.Tag == 1){
						that.collectionStatus = 0
						that.$alert('取消收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		GetRecommendBook(){ //获取推荐图书
			var that = this
			CheckInService.GetRecommendBook(that.$route.query.id).then(res=>{
				if(res.Tag == 1){
					console.log("RecommendBookInfo:",res);
					that.RecommendBookInfo = res.Result
				}
			})
		},
		toBookDetail(id){ //图书详情
			window.open(this.$WebUrl + 'BookDetail?id=' + id)
		},
		toElementDetail(value){ //词条详情
			if(value.type == 1  && value.ReleaseStatus != 0){
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
			}
		},
		readXml(){ //阅读
			var that = this
			if(that.$UserID){
				if(that.$ServiceType == 1){
					window.open(this.$WebUrl + 'XMLReading?filePath=' + that.EntryModelInfo.EntryModel.file_path)
				}else{
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定'
					});
				}
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		toLogin(){
			this.showLogin = false
			this.showLogin = true
		},
		keywordMore(){ //关键词展开收起
			$(".detail_keyword1").toggleClass("detail_keyword2")
		}
	},
	mounted(){
		this.GetEntryModel()
		this.GetRecommendBook()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'RelatedBook':RelatedBook,
		'RelatedElement':RelatedElement,
		'RelatedEntry':RelatedEntry,
		'RelatedImage':RelatedImage,
		'RelatedAudio':RelatedAudio,
		'RelatedVideo':RelatedVideo,
		'RelatedExport':RelatedExport,
		'LoginPop':LoginPop,
		'VIPLayer':VIPLayer
	}
}